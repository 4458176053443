import { useEffect, useRef, useState } from "react";
import AdminApp from "../../../components/layouts/admin-app";
import axios from "../../../utils/axios-config";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "react-sweetalert2";
import { SketchPicker } from "react-color";

export default function EditDataGeoportal() {
    const { id } = useParams();
    const navigate = useNavigate();
    const refForm = useRef();
    const [data, setData] = useState({
        name: '',
        source: '',
        category_id: '',
        data: '',
        markerType: 'default',
        defaultMarker: {
            color: {
                r: randomColor(0, 255),
                g: randomColor(0, 255),
                b: randomColor(0, 255),
                a: "0.7",
            },
            border: {
                r: randomColor(0, 255),
                g: randomColor(0, 255),
                b: randomColor(0, 255),
                a: "0.7",
            }
        },
        imageMarker: '',
        display: '0'
    });
    const [defaultColor, setDefaultColor] = useState({});
    const [formDisable, setFormDisable] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const [category, setCategory] = useState([]);

    useEffect(() => {
        setFormDisable(true);
        axios.get('/category/')
            .then(res => {
                setCategory(res.data.data);
            }).catch(err => {
                console.log(err)
            })

        axios.get(`/geoportal/${id}`)
            .then(res => {
                setData({
                    ...data,
                    name: res.data.data.name,
                    source: res.data.data.source,
                    category_id: res.data.data.category_id,
                    display: res.data.data.display,
                    markerType: res.data.data.markerType,
                    defaultMarker: JSON.parse(res.data.data.defaultMarker)
                })
                setDefaultColor(JSON.parse(res.data.data.defaultMarker));
                setFormDisable(false);
            }).catch(err => {
                console.log(err);
                setFormDisable(false);
            })
    }, [id]);

    function handleColor(name, color) {
        setData({
            ...data,
            defaultMarker: {
                ...data.defaultMarker,
                [name]: color
            }
        })
    }

    function randomColor(min, max){
        return min + Math.floor(Math.random() * (max - min + 1))
    }

    function handleInput(e) {
        if (e.target.name == 'markerType') {
            data.defaultMarker = {
                color: {
                    r: defaultColor.color.r,
                    g: defaultColor.color.g,
                    b: defaultColor.color.b,
                    a: defaultColor.color.a,
                },
                border: {
                    r: defaultColor.border.r,
                    g: defaultColor.border.g,
                    b: defaultColor.border.b,
                    a: defaultColor.border.a,
                }
            };
            data.imageMarker = '';
        }
        setData({ ...data, [e.target.name]: e.target.value })
    }

    function handleFile(e, type = '') {
        setFormDisable(true)
        if (type == 'main') {
            if (e.target.value.indexOf(".zip") !== -1) {
                generateFeatureCollection(e.target.value);
            } else if (e.target.value.indexOf(".csv") !== -1 || e.target.value.indexOf(".geojson") !== -1 || e.target.value.indexOf(".json") !== -1) {
                setFormDisable(false)
                setData({ ...data, data: e.target.files[0] })
            } else {
                setFormDisable(false)
                setData({ ...data, data: '' })
                console.log("GAGAL");
            }
        } else {
            setFormDisable(false);
            setData({ ...data, [e.target.name]: e.target.files[0] });
        }
    }

    function postData(e) {
        e.preventDefault()
        setFormDisable(true);
        const formdata = new FormData();
        formdata.append('name', data.name);
        formdata.append('source', data.source);
        formdata.append('category_id', data.category_id);
        formdata.append('data', data.data);
        formdata.append('markerType', data.markerType);
        formdata.append('defaultMarker', JSON.stringify(data.defaultMarker));
        formdata.append('imageMarker', data.imageMarker);
        formdata.append('display', data.display);
        axios.put(`/geoportal/${id}`, formdata)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Update Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            })
            .catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Update Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            })
    }

    async function generateFeatureCollection(fileName) {
        let name = fileName.split(".")
        name = name[0].replace("c:\\fakepath\\", "").replace("C:\\fakepath\\", "");
        const params = {
            name: name,
            maxRecordCount: 10000,
            enforceInputFileSizeLimit: true,
            enforceOutputJsonSizeLimit: true
        };

        params.generalize = true;
        params.maxAllowableOffset = 10;
        params.reducePrecision = true;
        params.numberOfDigitsAfterDecimal = 0;

        const myContent = {
            filetype: "shapefile",
            publishParameters: JSON.stringify(params),
            f: "json"
        };
        try {
            const res = await axios.post("https://www.arcgis.com/sharing/rest/content/features/generate",
                refForm.current, {
                params: myContent
            });
            // const layerName =
            //     res.data.featureCollection.layers[0].layerDefinition.name;
            setData({ ...data, data: JSON.stringify(res.data) });
            setFormDisable(false)
        } catch (err) {
            setFormDisable(false)
            console.log(err);
        }

    }

    return (
        <AdminApp>
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-6">
                    <div className="card">
                        <div className="card-header text-end">
                            <button className="btn btn-danger" onClick={() => { navigate(-1) }}>Kembali</button>
                        </div>
                        <form className="card-body" method="POST" encType="multipart/form-data" ref={refForm} onSubmit={(e) => postData(e)}>
                            <div className="form-group">
                                <label htmlFor="">Nama</label>
                                <input type="text" name="name" id="" className="form-control" value={data.name} onChange={(e) => handleInput(e)} placeholder="Data Batas Administrasi" disabled={formDisable} required />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Sumber</label>
                                <input type="text" name="source" id="" className="form-control" value={data.source} onChange={(e) => handleInput(e)} placeholder="Text/URL" disabled={formDisable} required />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">Category</label>
                                <select name="category_id" id="" className="form-control" value={data.category_id} onChange={(e) => handleInput(e)} disabled={formDisable} required >
                                    {
                                        category.map((val) => {
                                            return <option key={val.id} value={val.id}>{val.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="">File Geoportal <span className="text-muted">(.shp, .geojson, .csv)</span></label>
                                <input type="file" accept=".csv, .geojson, .json, .zip" name="file" id="" className="form-control" onChange={(e) => handleFile(e, 'main')} disabled={formDisable} />
                            </div>
                            <div className="form-group mt-3 d-flex justify-content-end">
                                <label htmlFor="display-check">Tampil Default</label>
                                <div className="mx-1"></div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="display-check" name="display" type="checkbox" checked={data.display === '1' ? true : false} onChange={(e) => setData({ ...data, display: data.display === '1' ? '0' : '1' })} />
                                </div>
                            </div>
                            <hr />
                            <div className="form-group mt-3">
                                <label htmlFor="">Marker</label>
                                <select name="markerType" id="" className="form-control" value={data.markerType} onChange={(e) => handleInput(e)} disabled={formDisable}>
                                    <option value="default">Default</option>
                                    <option value="image">Gambar</option>
                                </select>
                            </div>
                            {
                                data.markerType == 'default'
                                    ? <div className="row mt-3">
                                        <div className="form-group col-6">
                                            <label htmlFor="">Warna Marker</label>
                                            <SketchPicker
                                                onChange={(color) => {
                                                    handleColor("color", color.rgb);
                                                }}
                                                color={data.defaultMarker.color} />
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="">Warna Border Marker</label>
                                            <SketchPicker
                                                onChange={(color) => {
                                                    handleColor("border", color.rgb);
                                                }}
                                                color={data.defaultMarker.border} />
                                        </div>
                                    </div>
                                    : <div className="form-group mt-3">
                                        <label htmlFor="">Gambar Markeer <span className="text-muted">(.png, .jpg, .jpeg, .gif)</span></label>
                                        <input type="file" accept=".png, .jpg, .jpeg, .gif" name="imageMarker" id="" className="form-control" onChange={(e) => handleFile(e)} disabled={formDisable} />
                                    </div>
                            }
                            <div className="form-group mt-4 text-center">
                                <input type="submit" d="" className="btn btn-primary" value="Simpan" disabled={formDisable} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SweetAlert {...swalProps} />
        </AdminApp>
    )
}