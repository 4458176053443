import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios-config";

export default function Login() {
    const [information, setInformation] = useState({});
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        if(localStorage.getItem('token')){
            navigate('/admin')
        }

        axios.get('/information/')
            .then(res => {
                setInformation(res.data.data);
            }).catch(err => {
                console.log(err);
            })
    }, []);

    function handleInput(e){
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    function onSubmit(e) {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append('email', data.email);
        formdata.append('password', data.password);
        axios.post('/login/', formdata)
        .then(res => {
            if(res.data.error == true){
                alert(res.data.msg);
            }else{
                localStorage.setItem('token', res.data.data.token);
                navigate('/admin')
            }
        }).catch(err =>{
            console.log(err);
        })
    }

    return (
        <>
            <Helmet>
                <base href="./" />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
                <meta name="description" content={information.description} />
                <meta name="author" content="Lukman Hakim : wa.me/6285608658161" />
                <meta name="keyword" content={information.tag} />
                <title>{`Dashboard | ${information.title != undefined ? information.title : '...'}`}</title>
                <link rel="icon" href={`/information/${information.logo}`} />
                <link rel="apple-touch-icon" href={`/information/${information.logo}`} />
                <link rel="manifest" href="/themeadmin/assets/favicon/manifest.json" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/themeadmin/assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff" />
                <link rel="stylesheet" href="/themeadmin/vendors/simplebar/css/simplebar.css" />
                <link rel="stylesheet" href="/themeadmin/css/vendors/simplebar.css" />
                <link href="/themeadmin/css/style.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/prismjs@1.23.0/themes/prism.css" />
                <link href="/themeadmin/css/examples.css" rel="stylesheet" />
                <link href="/themeadmin/vendors/@coreui/chartjs/css/coreui-chartjs.css" rel="stylesheet" />

                <script src="/themeadmin/vendors/simplebar/js/simplebar.min.js" />
                {/* <script src="/themeadmin/vendors/chart.js/js/chart.min.js" /> */}
                <script src="/themeadmin/vendors/@coreui/chartjs/js/coreui-chartjs.js" />
                <script src="/themeadmin/vendors/@coreui/utils/js/coreui-utils.js" />
                {/* <script src="/themeadmin/js/main.js" /> */}
            </Helmet>
            <div className="bg-light min-vh-100 d-flex flex-row align-items-center px-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="card-group d-block d-md-flex row">
                                <div className="card col-md-7 p-4 mb-0">
                                    <form onSubmit={(e) => onSubmit(e)} className="card-body">
                                        <h1>Login</h1>
                                        <p className="text-medium-emphasis">Sign In to your account</p>
                                        <div className="input-group mb-3"><span className="input-group-text">
                                            <svg className="icon">
                                                <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-user"></use>
                                            </svg></span>
                                            <input className="form-control" name="email" type="email" value={data.email} onChange={(e) => handleInput(e)} placeholder="email@example.com" required />
                                        </div>
                                        <div className="input-group mb-4"><span className="input-group-text">
                                            <svg className="icon">
                                                <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-lock-locked"></use>
                                            </svg></span>
                                            <input className="form-control" name="password" type="password" value={data.password} onChange={(e) => handleInput(e)} placeholder="Password" required />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <button className="btn btn-primary px-4" type="submit">Login</button>
                                            </div>
                                            {/* <div className="col-6 text-end">
                                                <button className="btn btn-link px-0" type="button">Forgot password?</button>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}