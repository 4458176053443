import axios from "../../utils/axios-config";
import { useEffect, useState } from "react";
import AdminApp from "../../components/layouts/admin-app";
import ReactApexChart from 'react-apexcharts'

export default function Dashboard() {
    const [category, setCategory] = useState(0);
    const [geoportal, setGeoportal] = useState(0);
    const [sponsor, setSponsor] = useState(0);
    const [feedback, setFeedback] = useState(0);

    const [da, setDa] = useState({
        series: [category, geoportal, sponsor, feedback],
        options: {
            chart: {
                width: "100%",
                type: 'pie',
            },
            legend: {
                position: 'bottom'
            },
            labels: ['Team A', 'Team B', 'Team C', 'Team D'],

        },
    })

    useEffect(() => {
        axios.get('/category/')
            .then(res => {
                setCategory(res.data.data.length);
            }).catch(err => {
                console.log(err);
            });
        axios.get('/geoportal/')
            .then(res => {
                setGeoportal(res.data.data.length);
            }).catch(err => {
                console.log(err)
            });

        axios.get('/sponsor/')
            .then(res => {
                setSponsor(res.data.data.length);
            }).catch(err => {
                console.log(err)
            });
        axios.get('/feedback/')
            .then(res => {
                setFeedback(res.data.data.length);
            }).catch(err => {
                console.log(err);
            })
    }, []);

    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true
        },
    ];

    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '<span>1988</span>',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]

    return (
        <AdminApp>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="card mb-4 text-white bg-primary">
                        <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                            <div>
                                <div className="fs-4 fw-semibold">
                                    {category}
                                </div>
                                <div>Data Category</div>
                            </div>
                        </div>
                        <div className="c-chart-wrapper mt-3 mx-3" style={{ height: 70 }}>
                            {/* <canvas className="chart" id="card-chart1" height={70} /> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card mb-4 text-white bg-info">
                        <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                            <div>
                                <div className="fs-4 fw-semibold">
                                    {geoportal}
                                </div>
                                <div>Data Geoportal</div>
                            </div>
                        </div>
                        <div className="c-chart-wrapper mt-3 mx-3" style={{ height: 70 }}>
                            {/* <canvas className="chart" id="card-chart1" height={70} /> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card mb-4 text-white bg-warning">
                        <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                            <div>
                                <div className="fs-4 fw-semibold">
                                    {sponsor}
                                </div>
                                <div>Data Sponsor</div>
                            </div>
                        </div>
                        <div className="c-chart-wrapper mt-3 mx-3" style={{ height: 70 }}>
                            {/* <canvas className="chart" id="card-chart2" height={70} /> */}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="card mb-4 text-white bg-danger">
                        <div className="card-body pb-0 d-flex justify-content-between align-items-start">
                            <div>
                                <div className="fs-4 fw-semibold">
                                    {feedback}
                                </div>
                                <div>Data Feedback</div>
                            </div>
                        </div>
                        <div className="c-chart-wrapper mt-3 mx-3" style={{ height: 70 }}>
                            {/* <canvas className="chart" id="card-chart1" height={70} /> */}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body d-flex justify-content-center" style={{
                            overflow: 'auto'
                        }}>
                            <ReactApexChart options={{
                                chart: {
                                    width: "100%",
                                    type: 'pie',
                                },
                                legend: {
                                    position: 'bottom'
                                },
                                labels: ['Category', 'Geoportal', 'Sponsor', 'Feedback'],
                                colors:['#321fdb', '#39f', '#f9b115', '#e55353']
                            }} series={[category, geoportal, sponsor, feedback]} type="pie" width={400} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <DataTable
                columns={columns}
                data={data}
                customStyles={
                    {
                        headCells: {
                            style: {
                                fontWeight: 'bold',
                                backgroundColor: '#FFA500'
                            },
                        },
                    }
                }
                pagination
            /> */}
        </AdminApp>
    )
}