import { Link } from "react-router-dom";
import { base_url } from "../../utils/axios-config";
import '../../styles/sidebaradmin.css';
import { useState } from "react";

export default function AdminSidebar({ logo }) {
    const [sidebar, setSidebar] = useState(false);
    return (
        // <div className={`sidebar sidebar-dark sidebar-fixed ${sidebar ? '' : 'hide'}`} id="sidebar">
        //     <div className="sidebar-brand d-none d-md-flex">
        //         {
        //             logo && <img src={`${base_url}information/${logo}`} alt="Logo" height={50} />
        //         }
        //     </div>
        //     <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-speedometer" />
        //                 </svg>Dashboard
        //             </Link>
        //         </li>
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin/data-category">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-list" />
        //                 </svg>Data Kategori
        //             </Link>
        //         </li>
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin/data-geoportal">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-map" />
        //                 </svg>Data Geoportal
        //             </Link>
        //         </li>
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin/data-sponsor">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-image" />
        //                 </svg>Data Sponsor
        //             </Link>
        //         </li>
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin/feedback">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-comment-square" />
        //                 </svg>Feedback
        //             </Link>
        //         </li>
        //         <li className="nav-item">
        //             <Link className="nav-link" to="/admin/information">
        //                 <svg className="nav-icon">
        //                     <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-settings" />
        //                 </svg>Informasi
        //             </Link>
        //         </li>
        //     </ul>
        // </div>
        <div className={`adm-sidebar ${sidebar && 'show'}`}>
            <div className="adm-sidebar-brand">
                {
                    logo && <img src={`${base_url}information/${logo}`} alt="Logo" height={50} />
                }
            </div>
            <ul className="adm-sidebar-nav">
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-speedometer" />
                        </svg>Dashboard
                    </Link>
                </li>
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin/data-category">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-list" />
                        </svg>Data Kategori
                    </Link>
                </li>
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin/data-geoportal">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-map" />
                        </svg>Data Geoportal
                    </Link>
                </li>
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin/data-sponsor">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-image" />
                        </svg>Data Sponsor
                    </Link>
                </li>
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin/feedback">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-comment-square" />
                        </svg>Feedback
                    </Link>
                </li>
                <li className="adm-nav-item">
                    <Link className="adm-nav-link" to="/admin/information">
                        <svg className="adm-nav-icon">
                            <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-settings" />
                        </svg>Informasi
                    </Link>
                </li>
            </ul>
            <div className="adm-sidebar-close" onClick={() => setSidebar(!sidebar)}>
                <svg className="icon icon-lg">
                    <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-menu" />
                </svg>
            </div>
        </div>
    );
}