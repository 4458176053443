import axios from "../../../utils/axios-config";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import AdminApp from "../../../components/layouts/admin-app";

export default function AddDataSponsor() {
    const refImage = useRef();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [swalProps, setSwalProps] = useState({});
    const [formDisable, setFormDisable] = useState(false);

    function handleImage(e) {
        setFormDisable(true);
        setImage(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]))
        setFormDisable(false);
    }


    function postData(e) {
        e.preventDefault();
        setFormDisable(true);
        const formdata = new FormData();
        formdata.append('title', title);
        formdata.append('image', image);
        axios.post(`/sponsor/`, formdata)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Tambah Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            }).catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Tambah Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            })
    }

    return (
        <AdminApp>
            <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <form onSubmit={(e) => postData(e)} className="card-body">
                            <div className="form-group mb-3">
                                <label htmlFor="">Title</label>
                                <input type="text" name="title" id="" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Sponsor X" disabled={formDisable} required />
                            </div>
                            <div className="form-group d-flex flex-column align-items-center">
                                <label htmlFor="">Gambar Sponsor</label>
                                <input type="file" name="image" ref={refImage} onChange={(e) => handleImage(e)} hidden />
                                <img src={previewImage} style={{
                                    width: '50%',
                                    height: '300px',
                                    backgroundColor: '#DDDDDD',
                                    borderRadius: '5px',
                                    border: 'none',
                                    outline: 'none',
                                    cursor: 'pointer'
                                }} onClick={() => refImage.current.click()} />
                            </div>
                            <div className="form-group text-center mt-4">
                                <button type="submit" className="btn btn-primary">Simpan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SweetAlert2 {...swalProps} />
        </AdminApp>
    )
}