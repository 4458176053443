import { useEffect, useState } from "react";
import AdminApp from "../../../components/layouts/admin-app";
import axios, { base_url } from "../../../utils/axios-config";
import SweetAlert from "react-sweetalert2";

export default function DataFeedback() {
    const [limitData, setLimitData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [swalProps, setSwalProps] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        axios.get(`/feedback-paginate/?page=${currentPage}`)
            .then(res => {
                setLimitData(res.data.limitData)
                setTotalPage(res.data.totalPage)
                setData(res.data.data);
            }).catch(err => {
                console.log(err);
            })
    }, [refresh, currentPage])

    function deleteData(id) {
        axios.delete(`/feedback/${id}`)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            }).catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            })
    }

    function paginatePrev() {
        setCurrentPage(currentPage - 1)
    }

    function paginateNext() {
        setCurrentPage(currentPage + 1)
    }

    return (
        <AdminApp>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped">
                                    <thead className="bg-dark text-white">
                                        <tr>
                                            <th>No.</th>
                                            <th>Email</th>
                                            <th>Feedback</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((val, i) => {
                                                return <tr key={i}>
                                                    <td>{i + (limitData * (currentPage - 1)) + 1}</td>
                                                    <td>{val.email}</td>
                                                    <td>{val.content}</td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <button className="btn btn-danger btn-sm" onClick={() => setSwalProps({
                                                                key: val.id,
                                                                show: true,
                                                                title: 'Hapus Data',
                                                                text: 'Apakah anda yakin ingin menghapus data ini?',
                                                                icon: 'question',
                                                                showCancelButton: true,
                                                                confirmButtonText: "Delete",
                                                                confirmButtonColor: "#d33",
                                                                onConfirm: () => {
                                                                    deleteData(val.id)
                                                                }
                                                            })}>Hapus</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}><a className="page-link cursor-pointer" onClick={() => paginatePrev()}><span aria-hidden="true">&laquo;</span></a></li>
                                        <li className={`page-item`}><a className="page-link cursor-pointer">{currentPage}/{totalPage}</a></li>
                                        <li className={`page-item ${currentPage == totalPage ? 'disabled' : ''}`}><a className="page-link cursor-pointer" onClick={() => paginateNext()}><span aria-hidden="true">&raquo;</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert {...swalProps} />
        </AdminApp >
    )
}