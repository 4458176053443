import { useState } from "react";
import AdminApp from "../../../components/layouts/admin-app";
import axios from "../../../utils/axios-config";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-sweetalert2";

export default function AddDataCategory() {
    const navigate = useNavigate();
    const [data, setData] = useState({
        logo: '',
        name: '',
        display: '0'
    });
    const [formDisable, setFormDisable] = useState(false);
    const [swalProps, setSwalProps] = useState({})

    function handleInput(e) {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function handleFile(e){
        setData({
            ...data,
            [e.target.name]: e.target.files[0]
        })
    }

    function postData(e) {
        e.preventDefault();
        setFormDisable(true);
        const formdata = new FormData();
        formdata.append('name', data.name);
        formdata.append('display', data.display);
        formdata.append('logo', data.logo);
        axios.post(`/category/`, formdata)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Tambah Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            })
            .catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Tambah Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        navigate(-1)
                    }
                })
                setFormDisable(false);
            })
    }

    return (
        <AdminApp>
            <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-6">
                    <div className="card">
                        <div className="card-header text-end">
                            <button className="btn btn-danger" onClick={() => { navigate(-1) }}>Kembali</button>
                        </div>
                        <form className="card-body" method="POST" onSubmit={(e) => postData(e)}>
                            <div className="form-group mb-2">
                                <label htmlFor="">Image</label>
                                <input type="file" name="logo" id="" className="form-control" onChange={(e) => handleFile(e)} disabled={formDisable} required />
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="">Nama</label>
                                <input type="text" name="name" id="" className="form-control" value={data.name} onChange={(e) => handleInput(e)} placeholder="Category A" disabled={formDisable} required />
                            </div>
                            <div className="form-group mt-3 d-flex justify-content-end">
                                <label htmlFor="display-check">Tampil Default</label>
                                <div className="mx-1"></div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="display-check" name="display" type="checkbox" checked={data.display === '1' ? true : false} onChange={(e) => setData({ ...data, display: data.display === '1' ? '0' : '1' })} />
                                </div>
                            </div>
                            <div className="form-group mt-4 text-center">
                                <input type="submit" d="" className="btn btn-primary" value="Simpan" disabled={formDisable} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SweetAlert {...swalProps} />
        </AdminApp>
    )
}