import { useEffect, useState } from "react";
import AdminApp from "../../../components/layouts/admin-app";
import axios, { base_url } from "../../../utils/axios-config";
import { Link } from "react-router-dom";
import SweetAlert from "react-sweetalert2";

export default function DataGeoportal() {

    const [limitData, setLimitData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        setLoading(true);
        axios.get(`/geoportal-paginate/?page=${currentPage}`)
            .then(res => {
                setLoading(false)
                setLimitData(res.data.limitData)
                setTotalPage(res.data.totalPage)
                setData(res.data.data);
            }).catch(err => {
                setLoading(false)
                console.log(err);
            })
    }, [refresh, currentPage]);

    function deleteData(id) {
        axios.delete(`/geoportal/${id}`)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            }).catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            })
    }

    function paginatePrev() {
        setCurrentPage(currentPage - 1)
    }

    function paginateNext() {
        setCurrentPage(currentPage + 1)
    }

    return (
        <AdminApp>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/admin/data-geoportal/add" className="btn btn-primary">Tambah Data</Link>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover table-striped text-nowrap">
                                    <thead className="bg-dark text-white">
                                        <tr>
                                            <th>No.</th>
                                            <th>Nama</th>
                                            <th>Kategori</th>
                                            <th>Sumber</th>
                                            <th>Tampil Default</th>
                                            <th>Tipe Marker</th>
                                            <th>Tampilan Marker</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            loading ?
                                                <tr className="text-center">
                                                    <td colSpan={8}>Get Data...</td>
                                                </tr>
                                                :
                                                data.length > 0 ?
                                                    data.map((val, i) => {
                                                        const defaultMarker = JSON.parse(val.defaultMarker);
                                                        return <tr key={val.id}>
                                                            <td>{i + (limitData * (currentPage - 1)) + 1}</td>
                                                            <td>{val.name}</td>
                                                            <td>{val.ctgName}</td>
                                                            <td>{val.source}</td>
                                                            <td>{val.display === '1' ? <span className="badge bg-success">Ya</span> : <span className="badge bg-danger">Tidak</span>}</td>
                                                            <td>{val.markerType == 'image' ? 'Gambar' : 'Default'}</td>
                                                            <td>{val.markerType == 'image' ? <img src={`${base_url}marker/${val.imageMarker}`} width={30} alt="" /> : <div style={{ backgroundColor: `rgba(${defaultMarker.color.r}, ${defaultMarker.color.g}, ${defaultMarker.color.b}, ${defaultMarker.color.a})`, width: "24px", height: "24px", borderRadius: "4px", border: `2px solid rgba(${defaultMarker.border.r}, ${defaultMarker.border.g}, ${defaultMarker.border.b}, ${defaultMarker.border.a})` }}></div>}</td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    <Link to={`/admin/data-geoportal/edit/${val.id}`} className="btn btn-info btn-sm">Edit</Link>
                                                                    <button className="btn btn-danger btn-sm" onClick={() => setSwalProps({
                                                                        key: val.id,
                                                                        show: true,
                                                                        title: 'Hapus Data',
                                                                        text: 'Apakah anda yakin ingin menghapus data ini?',
                                                                        icon: 'question',
                                                                        showCancelButton: true,
                                                                        confirmButtonText: "Delete",
                                                                        confirmButtonColor: "#d33",
                                                                        onConfirm: () => {
                                                                            deleteData(val.id)
                                                                        }
                                                                    })}>Hapus</button></div>
                                                            </td>
                                                        </tr>
                                                    })
                                                    :
                                                    <tr className="text-center">
                                                        <td colSpan={8}>Data Kosong</td>
                                                    </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className={`page-item ${currentPage == 1 ? 'disabled' : ''}`}><a className="page-link cursor-pointer" onClick={() => paginatePrev()}><span aria-hidden="true">&laquo;</span></a></li>
                                        <li className={`page-item`}><a className="page-link cursor-pointer">{currentPage}/{totalPage}</a></li>
                                        <li className={`page-item ${currentPage == totalPage ? 'disabled' : ''}`}><a className="page-link cursor-pointer" onClick={() => paginateNext()}><span aria-hidden="true">&raquo;</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert {...swalProps} />
        </AdminApp>
    )
}