import axios from "axios";

export const base_url = 'https://medansatupeta-api.pemkomedan.go.id/';
// export const base_url = 'http://localhost:4000/';
export const weather_apikey = 'dd2f4ba57f72a4ea2c88da2ed8519f0e';

const instance = axios.create({
    baseURL: base_url
});

export default instance;