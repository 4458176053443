import { useState } from 'react';
import SweetAlert2 from 'react-sweetalert2';
import '../styles/modalfeedback.css';
import axios from '../utils/axios-config';

export default function ModalFeedback() {
    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        email: '',
        feedback: ''
    });
    const [swalProps, setSwalProps] = useState({});
    const [formDisable, setFormDisable] = useState(false);

    function toggleShow() {
        setShow(!show)
        document.body.style.overflow = !show ? 'hidden' : 'auto';
    }

    function handleInput(e) {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function onSubmit(e) {
        e.preventDefault();
        setFormDisable(true);
        const formdata = new FormData();
        formdata.append('email', data.email);
        formdata.append('content', data.feedback);
        axios.post(`/feedback/`, formdata)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Upload Feedback',
                    text: res.data.msg,
                    icon: 'success'
                })
                setFormDisable(false);
                setData({
                    email: '',
                    feedback: ''
                })
                toggleShow()
            }).catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Upload Feedback',
                    text: err.msg,
                    icon: 'error'
                })
                setData({
                    email: '',
                    feedback: ''
                })
                setFormDisable(false);
            })
    }

    return (
        <>
            <div className="btnShowFeedback" onClick={() => toggleShow()}>
                Feedback
            </div>
            <div className={`containerFeedback ${show ? 'active' : ''}`}>
                <div className="contentFeedback">
                    <div className="headerFeedback">
                        <h6>Feedback</h6>
                        <span>Masukkan Feedback Terbaikmu</span>
                    </div>
                    <hr />
                    <form onSubmit={(e) => onSubmit(e)} className="bodyFeedback">
                        <div className="form-group">
                            <label htmlFor="">E-mail</label>
                            <input type="email" name="email" id="" className="form-control" placeholder="guest@example.com" value={data.email} onChange={(e) => handleInput(e)} required disabled={formDisable} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Feedback</label>
                            <textarea name="feedback" id="" cols="30" rows="3" className="form-control" placeholder="......" value={data.feedback} onChange={(e) => handleInput(e)} required disabled={formDisable}></textarea>
                        </div>
                        <div className="form-group text-right mt-4">
                            <button type="button" className="btn btn-secondary mr-2" onClick={() => toggleShow()}>Cancel</button>
                            <input type="submit" value="Upload" className="btn btn-warning" />
                        </div>
                    </form>
                </div>
            </div>

            <SweetAlert2 {...swalProps} />
        </>
    )
}