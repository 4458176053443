import { useEffect, useRef, useState } from "react";
import AdminApp from "../../../components/layouts/admin-app";
import axios, { base_url } from "../../../utils/axios-config";
import SweetAlert from "react-sweetalert2";

export default function DataInformation() {
    const refImage = useRef();
    const [dataInfo, setDataInfo] = useState({
        title: '',
        description: '',
        tag: ''
    });
    const [dataAkun, setDataAkun] = useState({
        name: '',
        email: '',
        password: '',
    })
    const [image, setImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [swalProps, setSwalProps] = useState({});
    const [formDisable, setFormDisable] = useState(false);

    useEffect(() => {
        setFormDisable(true);
        axios.get('/information/')
            .then(res => {
                setDataInfo({
                    title: res.data.data.title,
                    description: res.data.data.description,
                    tag: res.data.data.tag,
                })
                if (res.data.data.logo != '') {
                    setPreviewImage(`${base_url}information/${res.data.data.logo}`)
                }
                setFormDisable(false);
            }).catch(err => {
                console.log(err);
                setFormDisable(false);
            })
        axios.get('/users/')
            .then(res => {
                setDataAkun({
                    name: res.data.data.name,
                    email: res.data.data.email
                })
            }).catch(err => {

            });
    }, []);

    function handleInput(e) {
        setDataInfo({ ...dataInfo, [e.target.name]: e.target.value });
    }

    function handleImage(e) {
        setImage(e.target.files[0])
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setFormDisable(true);
        const formdata = new FormData();
        formdata.append('title', dataInfo.title);
        formdata.append('description', dataInfo.description);
        formdata.append('tag', dataInfo.tag);
        formdata.append('logo', image);
        axios.post('/information', formdata)
            .then(res => {
                setFormDisable(false)
                setSwalProps({
                    show: true,
                    title: 'Update Informasi',
                    text: res.data.msg,
                    icon: 'success'
                })
            }).catch(err => {
                setFormDisable(false)
                setSwalProps({
                    show: true,
                    title: 'Update Informasi',
                    text: err.msg,
                    icon: 'error'
                })
                console.log(err);
            })
    }

    function handleInputUpdate(e) {
        setDataAkun({ ...dataAkun, [e.target.name]: e.target.value })
    }

    function handleUpdate(e) {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append('name', dataAkun.name);
        formdata.append('email', dataAkun.email);
        formdata.append('password', dataAkun.password);

        axios.post('/users', formdata)
            .then(res => {
                setFormDisable(false)
                setDataAkun({ ...dataAkun, password: '' });
                setSwalProps({
                    show: true,
                    title: 'Update Akun',
                    text: res.data.msg,
                    icon: 'success'
                })
            }).catch(err => {
                setFormDisable(false)
                setSwalProps({
                    show: true,
                    title: 'Update Akun',
                    text: err.msg,
                    icon: 'error'
                })
                console.log(err);
            })
    }

    return (
        <AdminApp>
            <div className="row">
                <div className="col-12 col-md-8 mb-3">
                    <div className="card">
                        <div className="card-header">
                            Informasi Website
                        </div>
                        <form onSubmit={(e) => handleSubmit(e)} className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-4 mb-3">
                                    <div className="form-group d-flex flex-column align-items-center">
                                        <input type="file" name="image" ref={refImage} onChange={(e) => handleImage(e)} hidden />
                                        <img src={previewImage} style={{
                                            width: '70%',
                                            height: '200px',
                                            backgroundColor: '#DDDDDD',
                                            borderRadius: '5px',
                                            border: 'none',
                                            outline: 'none',
                                            cursor: 'pointer'
                                        }} onClick={() => refImage.current.click()} />
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Title</label>
                                        <input type="text" name="title" id="" className="form-control" value={dataInfo.title || ''} onChange={(e) => handleInput(e)} placeholder="GIS Medan" disabled={formDisable} required />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Description</label>
                                        <textarea name="description" id="" className="form-control" value={dataInfo.description || ''} onChange={(e) => handleInput(e)} placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit...." disabled={formDisable} required />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="">Tag Keywoard</label>
                                        <input type="text" name="tag" id="" className="form-control" value={dataInfo.tag || ''} onChange={(e) => handleInput(e)} placeholder="gis,medan" disabled={formDisable} required />
                                    </div>
                                    <div className="form-group mb-3 mt-4 text-end">
                                        <input type="submit" className="btn btn-primary" disabled={formDisable} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <div className="card">
                        <div className="card-header">Akun</div>
                        <form onSubmit={(e) => handleUpdate(e)} className="card-body">
                            <div className="form-group mb-3">
                                <label htmlFor="">Name</label>
                                <input type="text" name="name" id="" className="form-control" value={dataAkun.name || ''} onChange={(e) => handleInputUpdate(e)} placeholder="Administrator" disabled={formDisable} required />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="">Email</label>
                                <input type="text" name="email" id="" className="form-control" value={dataAkun.email || ''} onChange={(e) => handleInputUpdate(e)} placeholder="admin@admin.com" disabled={formDisable} required />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="">Password (Optional)</label>
                                <input type="text" name="password" id="" className="form-control" value={dataAkun.password || ''} onChange={(e) => handleInputUpdate(e)} placeholder="admin" disabled={formDisable} />
                            </div>
                            <div className="form-group mb-3 mt-4 text-end">
                                <input type="submit" className="btn btn-primary" disabled={formDisable} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SweetAlert {...swalProps} />
        </AdminApp>
    )
}