import { useEffect, useState } from 'react';
import axios, { base_url, weather_apikey } from '../utils/axios-config';
import '../styles/weather.css'

export default function Weather() {
    const [weatherToggle, setWeatherToggle] = useState(false);
    const [data, setData] = useState({
        temp: 0,
        temp_max: 0,
        humidity: 0,
        wind: 0,
        pressure: 0,
        description: '',
        icon: ''
    });
    const [udara, setUdara] = useState({
        aqi: 0,
        mainPollutant: '',
        concentration: 0,
        WHOExposureColor: ''
    });

    useEffect(() => {
        axios.get(`https://api.openweathermap.org/data/2.5/weather?q=Medan&appid=${weather_apikey}&units=metric&lang=id`)
            .then(res => {
                setData({
                    temp: res.data.main.temp,
                    temp_max: res.data.main.temp_max,
                    humidity: res.data.main.humidity,
                    wind: res.data.wind.speed,
                    pressure: res.data.main.pressure,
                    description: res.data.weather[0].description,
                    icon: res.data.weather[0].icon
                });
            }).catch(err => {
                console.log(err);
            });

        axios.get(`${base_url}weather-air/`)
            .then(res => {
                // console.log(res.data.current);
                setUdara({
                    aqi: res.data.current.aqi,
                    mainPollutant: res.data.current.mainPollutant,
                    concentration: res.data.current.concentration,
                    WHOExposureColor: res.data.current.WHOExposure.WHOExposureColor
                })
            }).catch(err => {
                console.log(err);
            })
    }, [])


    return (
        <>
            <button className='weather-toggle' onClick={() => setWeatherToggle(!weatherToggle)}>
                {
                    weatherToggle ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-brightness-high" viewBox="0 0 16 16">
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                        </svg>
                }
            </button>
            <div className={`weather-container ${weatherToggle ? 'active' : ''}`}>
                <div className="weather-content">
                    <span className="air-label">Informasi Kualitas Udara</span>
                    <div className={`section-air air-bg-${udara.WHOExposureColor}`}>
                        <div className='air-icon'>
                            <img src={`https://www.iqair.com/assets/aqi/ic-face-${udara.WHOExposureColor}.svg`} alt="" className='air-face' />
                        </div>
                        <div className={`air-info air-text-${udara.WHOExposureColor}`}>
                            <div className='air-info-first'>
                                <span>{udara.aqi}</span>
                                <span>AQI US</span>
                            </div>
                            <div className="air-info-second">
                                {udara.mainPollutant.toUpperCase()} <strong>{udara.concentration} µg/m³</strong>
                            </div>
                        </div>
                    </div>
                    <div className="section-1">
                        <div className="weather-data">
                            <div className='weather-temp'>{data.temp.toFixed()}&deg;C</div>
                            <span>{data.description}</span>
                        </div>
                        <img src="https://openweathermap.org/img/wn/11d@2x.png" alt="" className='weather-icon' />
                    </div>
                    <div className="section-2">
                        <div className='weather-info'>Informasi Cuaca</div>
                        <div className="weather-info-data">
                            <div className="col-same">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-thermometer-half" viewBox="0 0 16 16">
                                    <path d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415z" />
                                    <path d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0V2.5zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1z" />
                                </svg>
                                <span>
                                    <div>{data.temp_max.toFixed()}&deg;C</div>
                                    <div>Suhu Max.</div>
                                </span>
                            </div>
                            <div className="col-same">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-droplet" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z" />
                                    <path fillRule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z" />
                                </svg>
                                <span>
                                    <div>{data.humidity}%</div>
                                    <div>Kelembaban</div>
                                </span>
                            </div>
                            <div className="col-same">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-wind" viewBox="0 0 16 16">
                                    <path d="M12.5 2A2.5 2.5 0 0 0 10 4.5a.5.5 0 0 1-1 0A3.5 3.5 0 1 1 12.5 8H.5a.5.5 0 0 1 0-1h12a2.5 2.5 0 0 0 0-5zm-7 1a1 1 0 0 0-1 1 .5.5 0 0 1-1 0 2 2 0 1 1 2 2h-5a.5.5 0 0 1 0-1h5a1 1 0 0 0 0-2zM0 9.5A.5.5 0 0 1 .5 9h10.042a3 3 0 1 1-3 3 .5.5 0 0 1 1 0 2 2 0 1 0 2-2H.5a.5.5 0 0 1-.5-.5z" />
                                </svg>
                                <span>
                                    <div>{data.wind} km/h</div>
                                    <div>Kec. Angin</div>
                                </span>
                            </div>
                            <div className="col-same">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                </svg>
                                <span>
                                    <div>{data.pressure} mbar</div>
                                    <div>Tekanan</div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}