import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Maps from "../../components/maps";
import Weather from "../../components/weather";
// import WeatherAir from "../../components/Weather-Air";
import "../../styles/user.css"
import axios, { base_url } from "../../utils/axios-config";

export default function User() {
    const [information, setInformation] = useState({});
    const [category, setCategory] = useState([])
    const refer = useRef();

    useEffect(() => {
        axios.get(`/information/`)
            .then(res => {
                setInformation(res.data.data);
            }).catch(err => {
                console.log(err);
            })

        axios.get(`/category/`)
            .then(res => {
                setCategory(res.data.data)
            }).catch(err => {
                console.log(err);
            })

    }, []);

    function toggleCategory(dataCtg) {
        if (refer != null) {
            window.scrollTo({
                left: 0,
                top: refer.current.mapRef.current.offsetTop,
                behavior: 'smooth'
            });
            refer.current.selectLayerByCategory(dataCtg.name)
        }
    }

    return (
        <>
            <Helmet>
                <base href="./" />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
                <meta name="description" content={information.description} />
                <meta name="author" content="Lukman Hakim : wa.me/6285608658161" />
                <meta name="keyword" content={information.tag} />
                <title>{information.title != undefined ? information.title : '...'}</title>
                <link rel="icon" href={`${base_url}information/${information.logo}`} />
                <link rel="apple-touch-icon" href={`${base_url}information/${information.logo}`} />
                <link
                    rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
                    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                    crossOrigin="anonymous"
                />
            </Helmet>

            <nav className="navbar navbar-dark position-sticky" style={{
                zIndex: '4',
                top: '0'
            }}>
                <a className="navbar-brand" href="#">
                    {
                        information.logo && <img src={`${base_url}information/${information.logo}`} width="30" height="30" alt="" className="mr-3" />
                    }
                    Medan Satu Peta
                </a>
            </nav>
            <Weather />
            {/* <WeatherAir /> */}
            <Maps ref={refer} />
            <div className="main-content px-5">
                <div className="container py-5">
                    <div className="row">
                        {
                            category.map((val, i) => {
                                return <div className="col-12 col-md-3 col-lg-2 mb-4" key={i} style={{
                                    cursor: 'pointer'
                                }}>
                                    <div className="card" onClick={() => toggleCategory(val)}>
                                        <div className="card-body text-center">
                                            <img src={`${base_url}category/${val.logo}`} alt="" className="img-fluid rounded-circle" style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'cover'
                                            }} />
                                            <div className="font-weight-bold text-center mt-3 text-truncate">{val.name}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}