import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { base_url } from "../../utils/axios-config";

export default function AdminHeader({ title, logo }) {
    const navigate = useNavigate();
    const [popupProfile, setPopupProfile] = useState(false);

    function onLogout() {
        localStorage.removeItem('token');
        navigate('/admin/login')
    }

    return (
        <header className="header header-sticky mb-4">
            <div className="container-fluid">
                <button
                    className="header-toggler px-md-0 me-md-3"
                    type="button"
                >
                    <span className="d-none d-md-block">
                        {
                            title
                        }
                    </span>
                    {/* <svg className="icon icon-lg" onClick={() => sidebarToggle()}>
                        <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-menu" />
                    </svg> */}
                </button>
                <a className="header-brand d-md-none" href="/">
                    {/* <svg width={118} height={46} alt="CoreUI Logo">
                        <use xlinkHref="/themeadmin/assets/brand/coreui.svg#full" />
                    </svg> */}
                    {
                        logo && <img src={`${base_url}information/${logo}`} alt="Logo" height={46} />
                    }
                </a>
                <ul className="header-nav ms-3">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link py-0"
                            data-coreui-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="true"
                            onClick={() => setPopupProfile(!popupProfile)}
                        >
                            <div className="avatar avatar-md">
                                <img
                                    className="avatar-img"
                                    src="/themeadmin/assets/img/avatars/8.jpg"
                                    alt="user@email.com"
                                />
                            </div>
                        </a>
                        <div
                            className={`dropdown-menu dropdown-menu-end pt-0 ${popupProfile ? 'show' : ''}`}
                            style={
                                popupProfile ? {
                                    position: "absolute",
                                    inset: "0px 0px auto auto",
                                    margin: "0px",
                                    transform: "translate(0px, 42px)"
                                } : {}
                            }>
                            <div className="dropdown-header bg-light py-2">
                                <div className="fw-semibold">Account</div>
                            </div>
                            <a className="dropdown-item" style={{
                                cursor: 'pointer'
                            }} onClick={() => onLogout()}>
                                <svg className="icon me-2">
                                    <use xlinkHref="/themeadmin/vendors/@coreui/icons/svg/free.svg#cil-account-logout" />
                                </svg>Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    );
}