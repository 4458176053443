import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddDataCategory from "./pages/admin/category/add";
import DataCategory from "./pages/admin/category/data";
import EditDataCategory from "./pages/admin/category/edit";
import Dashboard from "./pages/admin/dashboard";
import DataFeedback from "./pages/admin/feedback/data";
import AddDataGeoportal from "./pages/admin/geoportal/add";
import DataGeoportal from "./pages/admin/geoportal/data";
import EditDataGeoportal from "./pages/admin/geoportal/edit";
import DataInformation from "./pages/admin/information/data";
import AddDataSponsor from "./pages/admin/sponsor/add";
import DataSponsor from "./pages/admin/sponsor/data";
import Login from "./pages/admin/login";
import User from "./pages/user/user";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<User />} />
        <Route exact path="/admin" element={<Dashboard />} />

        <Route exact path="/admin/login" element={<Login />} />

        <Route exact path="/admin/data-category" element={<DataCategory />} />
        <Route exact path="/admin/data-category/add" element={<AddDataCategory />} />
        <Route exact path="/admin/data-category/edit/:id" element={<EditDataCategory />} />

        <Route exact path="/admin/data-geoportal" element={<DataGeoportal />} />
        <Route exact path="/admin/data-geoportal/add" element={<AddDataGeoportal />} />
        <Route exact path="/admin/data-geoportal/edit/:id" element={<EditDataGeoportal />} />

        <Route exact path="/admin/data-sponsor" element={<DataSponsor />} />
        <Route exact path="/admin/data-sponsor/add" element={<AddDataSponsor />} />

        <Route exact path="/admin/feedback" element={<DataFeedback />} />

        <Route exact path="/admin/information" element={<DataInformation />} />
      </Routes>
    </BrowserRouter>
  )
}