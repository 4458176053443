import axios, { base_url } from "../../../utils/axios-config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import AdminApp from "../../../components/layouts/admin-app";

export default function DataSponsor() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        setLoading(true);
        axios.get('/sponsor/')
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            }).catch(err => {
                console.log(err)
                setLoading(false);
            })
    }, [refresh]);

    function deleteData(id) {
        axios.delete(`/sponsor/${id}`)
            .then(res => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: res.data.msg,
                    icon: 'success',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            }).catch(err => {
                setSwalProps({
                    show: true,
                    title: 'Delete Data',
                    text: err.msg,
                    icon: 'error',
                    didClose: () => {
                        setRefresh(id)
                    }
                })
            })
    }

    return (
        <AdminApp>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/admin/data-sponsor/add" className="btn btn-primary">Tambah Data</Link>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {
                                    loading
                                        ? <h6 className="text-center">Loading...</h6>
                                        : data.map((val) => {
                                            return <div className="col-6 col-md-3 col-lg-2 mb-4 position-relative" key={val.id}>
                                                <button className="btn btn-sm btn-danger" style={{
                                                    position: 'absolute',
                                                    color: 'white',
                                                    right: '0',
                                                    padding: '5px 10px',
                                                    borderRadius: '100px'
                                                }} onClick={() => setSwalProps({
                                                    key: val.id,
                                                    show: true,
                                                    title: 'Hapus Data',
                                                    text: 'Apakah anda yakin ingin menghapus data ini?',
                                                    icon: 'question',
                                                    showCancelButton: true,
                                                    confirmButtonText: "Delete",
                                                    confirmButtonColor: "#d33",
                                                    onConfirm: () => {
                                                        deleteData(val.id)
                                                    }
                                                })}>X</button>
                                                <img src={`${base_url}sponsor/${val.image}`} alt="" className="img-fluid" />
                                            </div>
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SweetAlert2 {...swalProps} />
        </AdminApp>
    )
}