import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import AdminHeader from "./admin-header";
import AdminSidebar from "./admin-sidebar";
import axios, { base_url } from "../../utils/axios-config";
import { useNavigate } from "react-router-dom";

export default function AdminApp({ children }) {
    const navigate = useNavigate();
    const [information, setInformation] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post(`/checkAuth`, {
                id: new Date()
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                if (res.data.status == 404) {
                    localStorage.removeItem('token');
                    navigate('/admin/login');
                }
            }).catch(err => {
                console.log(err)
            });
        } else {
            localStorage.removeItem('token');
            navigate('/admin/login')
        }

        axios.get('/information/')
            .then(res => {
                setInformation(res.data.data);
            }).catch(err => {
                console.log(err);
            })
    }, []);


    return (
        <>
            <Helmet>
                <base href="./" />
                <meta charset="utf-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
                <meta name="description" content={information.description} />
                <meta name="author" content="Lukman Hakim : wa.me/6285608658161" />
                <meta name="keyword" content={information.tag} />
                <title>{`Dashboard | ${information.title != undefined ? information.title : '...'}`}</title>
                {
                    information.logo && <link rel="icon" href={`${base_url}information/${information.logo}`} />
                }
                {
                    information.logo && <link rel="apple-touch-icon" href={`${base_url}information/${information.logo}`} />
                }
                <link rel="manifest" href="/themeadmin/assets/favicon/manifest.json" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="msapplication-TileImage" content="/themeadmin/assets/favicon/ms-icon-144x144.png" />
                <meta name="theme-color" content="#ffffff" />
                <link rel="stylesheet" href="/themeadmin/vendors/simplebar/css/simplebar.css" />
                <link rel="stylesheet" href="/themeadmin/css/vendors/simplebar.css" />
                <link href="/themeadmin/css/style.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/prismjs@1.23.0/themes/prism.css" />
                <link href="/themeadmin/css/examples.css" rel="stylesheet" />
                <link href="/themeadmin/vendors/@coreui/chartjs/css/coreui-chartjs.css" rel="stylesheet" />

                <script src="/themeadmin/vendors/simplebar/js/simplebar.min.js" />
                {/* <script src="/themeadmin/vendors/chart.js/js/chart.min.js" /> */}
                <script src="/themeadmin/vendors/@coreui/chartjs/js/coreui-chartjs.js" />
                <script src="/themeadmin/vendors/@coreui/utils/js/coreui-utils.js" />
                {/* <script src="/themeadmin/js/main.js" /> */}
            </Helmet>
            <AdminSidebar logo={information.logo} />

            <div className="wrapper d-flex flex-column min-vh-100 bg-light" style={{
                width: '100%',
                padding: '0'
            }}>
                <AdminHeader title={information.title} logo={information.logo} />

                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        {children}
                    </div>
                </div>

                <footer className="footer mt-4">
                    <div>
                        <a href="https://coreui.io">CoreUI </a>
                        <a href="https://coreui.io">Bootstrap Admin Template</a> © 2022
                        creativeLabs.
                    </div>
                    <div className="ms-auto">
                        Powered by&nbsp;<a href="https://coreui.io/docs/">CoreUI UI Components</a>
                    </div>
                </footer>
            </div>
        </>
    )
}