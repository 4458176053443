import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { loadModules } from "esri-loader";
import '../styles/maps.css';
import { useState } from "react";
import axios, { base_url } from "../utils/axios-config";
import ModalFeedback from "./modal-feedback";

function Maps(props, ref) {
    const mapRef = useRef(null);
    const [sidebarContainer, setSidebarContainer] = useState(false);
    const [geoportal, setGeoportal] = useState([]);
    const [category, setCategory] = useState([]);
    const [sponsor, setSponsor] = useState([]);
    const [basemap, setBasemap] = useState("streets-navigation-vector");
    const [information, setInformation] = useState({});


    useEffect(() => {

        axios.get(`${base_url}information/`)
            .then(res => {
                setInformation(res.data.data);
            }).catch(err => {
                console.log(err);
            })

        axios.get(`${base_url}sponsor/`)
            .then(res => {
                setSponsor(res.data.data)
            }).catch(err => {
                console.log(err);
            })

        axios.get(`${base_url}category/`)
            .then(res => {
                setCategory(res.data.data)
            }).catch(err => {
                console.log(err);
            })


        // Maps
        loadModules([
            "esri/views/MapView",
            "esri/Map",
            "esri/layers/FeatureLayer",
            "esri/layers/GeoJSONLayer",
            "esri/layers/support/Field",
            "esri/Graphic",
            "esri/widgets/Search"
        ], { css: true })
            .then(([MapView, Map, FeatureLayer, GeoJSONLayer, Field, Graphic, Search]) => {

                const map = new Map({
                    basemap: basemap
                });


                const view = new MapView({
                    container: mapRef.current,
                    map: map,
                    zoom: 10,
                    center: [98.678513, 3.597031]
                });

                view.surface.addEventListener("wheel", function (event) {
                    event.stopImmediatePropagation();
                }, true);

                const searchWidget = new Search({
                    view: view
                });

                view.ui.add(searchWidget, {
                    position: "top-right"
                });

                if (geoportal.length === 0) {
                    axios.get(`${base_url}geoportal/`)
                        .then(res => {
                            res.data.data.forEach(val => {
                                if (val.file.indexOf('.json') !== -1) {
                                    if (val.filedata.featureCollection == null) {
                                        console.log(val.name + " GAGAL");
                                    } else {
                                        const layers = val.filedata.featureCollection.layers.map((layer) => {
                                            const graphics = layer.featureSet.features.map((feature) => {
                                                return Graphic.fromJSON(feature);
                                            });

                                            return new FeatureLayer({
                                                objectIdField: "FID",
                                                source: graphics,
                                                fields: layer.layerDefinition.fields.map((field) => {
                                                    return Field.fromJSON(field);
                                                }),
                                                renderer: renderer(val, graphics[0].geometry.type),
                                            });
                                            // associate the feature with the popup on click to enable highlight and zoom to
                                        });

                                        layers[0].visible = val.display === '1' ? true : false;
                                        geoportal.push({
                                            id: val.id,
                                            category: val.ctgName,
                                            name: val.name,
                                            display: val.display === '1' ? true : false,
                                            type: 'layers',
                                            data: layers
                                        });
                                        setGeoportal(geoportal);
                                        map.addMany(layers);
                                    }
                                } else if (val.file.indexOf('.geojson') !== -1) {

                                    const geoJsonLayer = new GeoJSONLayer({
                                        url: `${base_url}geoportal-open-geojson/${val.file}`,
                                        copyright: val.name,
                                    });

                                    geoJsonLayer.visible = val.display === '1' ? true : false;
                                    geoportal.push({
                                        id: val.id,
                                        category: val.ctgName,
                                        name: val.name,
                                        display: val.display === '1' ? true : false,
                                        type: 'layer',
                                        data: geoJsonLayer
                                    });
                                    // console.log(geoJsonLayer.geometryType);
                                    geoJsonLayer.when(function () {
                                        // console.log(geoJsonLayer.geometryType);
                                        geoJsonLayer.renderer = renderer(val, geoJsonLayer.geometryType);
                                        setGeoportal(geoportal);
                                    });
                                    map.add(geoJsonLayer)
                                }
                            });
                        }).catch(err => {
                            console.log(err);
                        })
                } else {
                    geoportal.forEach(val => {
                        if (val.type === 'layers') {
                            map.addMany(val.data);
                        } else {
                            map.add(val.data);
                        }
                    })
                }
            });
    }, [basemap]);

    function renderer(val, type) {
        let renderer = {};
        if (val.markerType === 'default') {
            const defaultMarker = JSON.parse(val.defaultMarker);
            switch (type) {
                case "polygon":
                    renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-fill",
                            color: [defaultMarker.color.r, defaultMarker.color.g, defaultMarker.color.b, defaultMarker.color.a],
                            outline: {
                                width: 0.5,
                                color: [defaultMarker.border.r, defaultMarker.border.g, defaultMarker.border.b, defaultMarker.border.a]
                            }
                        }
                    }
                    break;
                case "polyline":
                    renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-line",
                            color: [defaultMarker.color.r, defaultMarker.color.g, defaultMarker.color.b, defaultMarker.color.a],
                        }
                    }
                    break;
                case "point":
                    renderer = {
                        type: "simple",
                        symbol: {
                            type: "simple-marker",
                            color: [defaultMarker.color.r, defaultMarker.color.g, defaultMarker.color.b, defaultMarker.color.a],
                            size: 6,
                            outline: {
                                width: 0.5,
                                color: [defaultMarker.border.r, defaultMarker.border.g, defaultMarker.border.b, defaultMarker.border.a]
                            }
                        }
                    }
                    break;
            }
        } else {
            renderer = {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    width: 24,
                    height: 24,
                    url: `${base_url}marker/${val.imageMarker}`
                }
            }
        }
        return renderer;
    }

    function toggleCtg(id) {
        const newData = category.map((val) => {
            if (val.id === id) {
                val.display = !val.display;
            }
            return val;
        })
        setCategory(newData)
    }

    function toggleLayer(id) {
        const newData = geoportal.map((val) => {
            if (val.id === id) {
                val.display = !val.display;
                if (val.data.length === undefined) {
                    val.data.visible = val.display;
                } else {
                    val.data[0].visible = val.display;
                }
            }
            return val;
        })
        setGeoportal(newData)
    }

    function selectLayerByCategory(ctgName) {
        const newCtg = category.map((val) => {
            if (val.name.replaceAll(' ', '') === ctgName.replaceAll(' ', '')) {
                val.display = true;
            } else {
                val.display = false;
            }
            return val;
        })
        setCategory(newCtg)

        const newGeo = geoportal.map((val) => {
            if (val.category.replaceAll(' ', '') === ctgName.replaceAll(' ', '')) {
                val.display = true;
            } else {
                val.display = false;
            }
            if (val.data.length === undefined) {
                val.data.visible = val.display;
            } else {
                val.data[0].visible = val.display;
            }
            return val;
        })
        setGeoportal(newGeo)
    }

    function toggleSidebar() {
        setSidebarContainer(!sidebarContainer)
        document.body.style.overflow = !sidebarContainer ? 'hidden' : 'auto';
    }

    useImperativeHandle(ref, () => ({
        mapRef: mapRef,
        selectLayerByCategory: selectLayerByCategory
    }))

    return (
        <>
            <div style={{
                position: 'relative'
            }}>
                <button className="btnShowSidebar" onClick={() => toggleSidebar()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
                <div className={`containerSidebar ${sidebarContainer ? 'active' : ''}`}>
                    <div className="headerSidebar">
                        <button className="btnHideSidebar" onClick={() => toggleSidebar()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                                <path fillRule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                        <p className="titleHeaderSidebar">Layer Services</p>

                    </div>
                    <div className="bodySidebar">
                        <div className="bodySidebar-styleMap">
                            {
                                ['satellite', 'hybrid', 'terrain', 'oceans', 'osm', 'dark-gray-vector', 'gray-vector', 'streets-vector', 'topo-vector', 'strets-night-vector', 'streets-relief-vector', 'streets-navigation-vector'].map((val, i) => {
                                    return <span key={i} style={{
                                        display: 'flex',
                                        alignItems: 'center!important',
                                        marginTop: '5px!important',
                                    }}>
                                        <input type="radio" name="stylemap" onChange={() => setBasemap(val)} id={`stylemap-${i}`} checked={val === basemap} />
                                        <label style={{ fontSize: '11px', cursor: 'pointer', marginRight: '7px', marginLeft: '3px', marginTop: '6px' }} htmlFor={`stylemap-${i}`}>{val.replaceAll('-', ' ')}</label>
                                    </span>
                                })
                            }
                        </div>
                        <br />
                        <hr />
                        <div className="bodySidebar-dataMap">
                            {
                                category.map((valCtg, i) => {
                                    return <div key={valCtg.id}>
                                        <div className="ctg-header" onClick={() => toggleCtg(valCtg.id)}>
                                            <span>{valCtg.name}</span>
                                            {
                                                valCtg.display == '1' ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                            }
                                        </div>
                                        <div className={`ctg-body ${valCtg.display == '1' ? 'active' : ''}`}>
                                            {
                                                geoportal.map((val) => {
                                                    if (val.category.replaceAll(' ', '') === valCtg.name.replaceAll(' ', '')) {
                                                        return <div key={val.id} style={{
                                                            display: 'flex',
                                                            alignItems: 'start',
                                                            marginTop: '5px',
                                                        }}>
                                                            <input type="checkbox" name={val.id} id={`check-${val.id}`} checked={val.display} onChange={() => toggleLayer(val.id)} />
                                                            <label htmlFor={`check-${val.id}`} style={{ fontSize: '11px', cursor: 'pointer', marginLeft: '3px' }}>{val.name}</label>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <ModalFeedback />
                <div className="containerSponsor">
                    {
                        information.logo && <img className="imgLogo" src={`${base_url}information/${information.logo}`} alt="" />
                    }
                    <div className="listSponsor">
                        {
                            sponsor.map((val) => {
                                return <img key={val.id} className="imgSponsor" src={`${base_url}sponsor/${val.image}`} alt="" />
                            })
                        }
                    </div>
                </div>
                <div style={{ height: '95vh', width: '100%', border: 'none', outline: 'none' }} ref={mapRef}></div>
            </div>
        </>
    );
}

export default forwardRef(Maps)